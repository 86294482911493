import React, { useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import ActionsPerMinuteComponent from './ActionsPerMinuteComponent';
import ActionsPerMinuteMenu from './ActionsPerMinuteMenu';

const INIT_STATE_VALUE = {
  targetEl: null,
  actionsPerMinute: null,
  onChange: null,
  minPublicActionsMonth: null,
  minPublicActionsUser: null,
  path: null,
};

const useStyles = makeStyles({ name: 'ActionsPerMinuteButton' })((theme) => ({
  button: {
    background: 'white',
    gap: theme.spacing(1.25),
    '& .MuiButton-endIcon': {
      marginLeft: 0,
      paddingLeft: theme.spacing(0.5),
      borderLeft: `1px ${theme.palette.divider} solid`,
    },
  },
  label: { flexGrow: 1 },
}));

const ActionsPerMinuteButton = ({
  actionsPerMinute,
  skeleton,
  disabled,
  onChange = () => {},
  minUserActionsMonth,
  ...ComponentProps
}) => {
  const { classes: styles } = useStyles();

  const [menuOptions, setMenuOptions] = useState(INIT_STATE_VALUE);

  const handleMenuSet = (props) => {
    if (typeof props === 'string' && props === 'reset') {
      setMenuOptions(INIT_STATE_VALUE);
    } else {
      setMenuOptions(props);
    }
  };

  const handleClick = (e) => {
    e?.stopPropagation();

    handleMenuSet({
      targetEl: e.currentTarget,
      actionsPerMinute,
      onChange,
      minUserActionsMonth,
    });
  };

  return (
    <>
      <Button
        className={styles.button}
        color="inherit"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        disabled={skeleton || disabled}
        fullWidth
      >
        <ActionsPerMinuteComponent
          actionsPerMinute={actionsPerMinute}
          skeleton={skeleton}
          disabled={disabled}
          suppressInherit={true}
          labelProps={{ className: styles.label }}
          {...ComponentProps}
        />
      </Button>
      <ActionsPerMinuteMenu menuOptions={menuOptions} setMenuOptions={handleMenuSet} />
    </>
  );
};

export default ActionsPerMinuteButton;

import React, { useState } from 'react';

import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import useStyles from './useFormSideStyles';

const FirstFormTab = ({
  validationToken,
  username,
  handleUsernameChange,
  email,
  handleEmailChange,
  phone,
  handlePhoneChange,
  password,
  handlePasswordChange,

  errors,
}) => {
  const { classes } = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className={classes.textFieldContainer}>
        <TextField
          label="Username*"
          InputLabelProps={{ shrink: true }}
          placeholder="e.g. yourUsername"
          value={username}
          onChange={handleUsernameChange}
          variant="outlined"
          type="text"
          autoComplete="username"
          className={classes.textField}
          helperText={errors.username}
          error={errors.username !== ''}
        />
      </div>
      {/* false && (
        <div className={classes.textFieldContainer}>
          <Typography component="label" htmlFor="phone-field" className={classes.textFieldLabel}>
            Phone
          </Typography>
          <TextField
            id="phone-field"
            placeholder="e.g. 0123456789"
            value={phone}
            onChange={handlePhoneChange}
            variant="outlined"
            type="tel"
            autoComplete="tel"
            className={classes.textField}
            helperText={errors.phone}
            error={errors.phone !== ''}
          />
        </div>
      ) */}
      {!validationToken && (
        <div className={classes.textFieldContainer}>
          <TextField
            label="Email Address*"
            InputLabelProps={{ shrink: true }}
            placeholder="e.g. you@company.com"
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            type="email"
            autoComplete="email"
            className={classes.textField}
            helperText={errors.email}
            error={errors.email !== ''}
          />
        </div>
      )}
      <div className={classes.textFieldContainer}>
        <TextField
          label="Password*"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            sx: { pr: 0.5 },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Enter a strong password"
          value={password}
          onChange={handlePasswordChange}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          className={classes.textField}
          helperText={errors.password}
          error={errors.email !== ''}
        />
      </div>
    </>
  );
};

export default FirstFormTab;

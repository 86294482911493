import React from 'react';

import FileSaver from 'file-saver';
import streamSaver from 'streamsaver';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import moment from 'moment';
// import Geocode from 'react-geocode';

// Geocode.setApiKey('AIzaSyD7St73oH9DkelrROCaZqUd7-cR80WPeUQ');

const TILE = 'image_tile';
const MAP_TILE = 'map_tile';
const STANDARD_TILE = 'tile';
const POLYGON = 'polygon';
const CUSTOM_POLYGON = 'customPolygon';
const IMPORTED_POLYGON = 'importedPolygon';
const WMS_TILE = 'wms_tile';
const WFS_LAYER = 'WFS';

const NOT_IN_URL = undefined;

const VIEWER_CONFIG = [
  { key: 'hideNavbar', check: 'hideNavbar' },
  { key: 'hideSidePane', check: ['hideSidebar', 'hideSidePane'] },
  { key: 'hideOriginMapActions', check: 'hideOriginMapActions' },
  { key: 'hideBrowse', check: 'hideBrowse' },
  { key: 'hideShareView', check: 'hideShareView' },
  { key: 'hideSaveButton', check: 'hideSaveButton' },
  { key: 'hideCreateDrawLayer', check: 'hideCreateDrawLayer' },
  { key: 'hide3D', check: 'hide3D' },
  { key: 'hideLayerActions', check: 'hideLayerActions' },
  { key: 'hideLayerControls', check: 'hideLayerControls' },
  { key: 'hideLayerIntegrate', check: 'hideLayerIntegrate' },
  { key: 'hideLayerActionChangelog', check: 'hideLayerActionChangelog' },
  { key: 'hideLayerActionFeed', check: 'hideLayerActionFeed' },
  { key: 'hideLayerActionTable', check: 'hideLayerActionTable' },
  { key: 'hideLayerActionCenter', check: 'hideLayerActionCenter' },
  { key: 'hideLayerActionDownload', check: 'hideLayerActionDownload' },
  { key: 'hideLayerActionReload', check: 'hideLayerActionReload' },
  { key: 'hideLayerActionDisplayUploads', check: 'hideLayerActionDisplayUploads' },
  { key: 'hideLayerControlsAddFeature', check: 'hideLayerControlsAddFeature' },
  { key: 'hideLayerControlsStyling', check: 'hideLayerControlsStyling' },
  { key: 'hideLayerControlsFilters', check: 'hideLayerControlsFilter' },
  { key: 'hideLayerControlsTune', check: 'hideLayerControlsTune' },
  { key: 'hideLayerControlsAnalyse', check: 'hideLayerControlsAnalyse' },
  { key: 'hideLayerControlsTimestamps', check: 'hideLayerControlsTimestamps' },
  { key: 'hideLayerControlsCenterPoints', check: 'hideLayerControlsCenterPoints' },
  { key: 'hideFeatureSeries', check: 'hideFeatureSeries' },
  { key: 'hideFeatureButtons', check: 'hideFeatureButtons' },
  { key: 'hideFeatureMessages', check: 'hideFeatureMessages' },
  { key: 'hideFeatureVersions', check: 'hideFeatureVersions' },
  { key: 'hideLayerButtons', check: 'hideLayerButtons' },
  { key: 'hideLayerMore', check: 'hideLayerMore' },
  { key: 'hideAdvanced', check: 'hideAdvanced' },
  { key: 'hideViewerControls', check: 'hideViewerControls' },
  { key: 'vectorTileOnly', check: 'vectorTileOnly' },

  { key: 'showLogo', check: 'showLogo' },
];

export const PRESENTATION_MODE = 'presentationMode';
const PRESENTATION_MODE_PRESET = { hideNavbar: true, showLogo: true, presentationMode: true };

const ViewerUtility = {
  admin: 'admin',

  tileLayerType: TILE,
  mapLayerType: MAP_TILE,
  standardTileLayerType: STANDARD_TILE,
  wmsTileLayerType: WMS_TILE,
  wfsLayerType: WFS_LAYER,
  samplePointLayerType: 'SAMPLE_POINT',
  polygonLayerType: POLYGON,
  importedPolygonLayerType: IMPORTED_POLYGON,
  customPolygonTileLayerType: CUSTOM_POLYGON,

  dataType: {
    class: 'class',
    meanMeasurement: 'meanMeasurement',
    deviationMeasurement: 'deviationMeasurement',
  },

  drawnPolygonLayerType: 'drawn_polygon',

  tileLayerZIndex: 100,
  importedMapLayerZIndex: 150,
  mapLayerZIndex: 200,
  standardTileLayerZIndex: 300,
  polygonLayerZIndex: 400,
  customPolygonLayerZIndex: 500,
  selectedElementLayerZIndex: 600,
  drawnPolygonLayerZIndex: 625,
  markerZIndex: 650,
  searchBoundLayerZIndex: 800,

  dataPaneAction: {
    analyse: 'analyse',
    analyseRaster: 'analyse_raster',
    geoMessage: 'geoMessage',
    createCustomPolygon: 'create_custom_polygon',
    editCustomPolygon: 'edit_custom_polygon',
    feed: 'geomessage_feed',
    classification: 'classification',
    analyseGeometry: 'analyse',
  },

  dataGraphType: {
    classes: 'classes',
    measurements: 'measurements',
  },

  specialClassName: {
    allClasses: 'all classes',
    mask: 'mask',
    outside_area: 'outside_area',
    noClass: 'no class',
    cloudCover: 'cloud_cover',
  },

  geomessageFormType: {
    text: 'text',
    numeric: 'numeric',
    boolean: 'boolean',
  },

  flyToType: {
    map: 'map',
    bounds: 'bounds',
    currentLocation: 'current_location',
    currentElement: 'current_element',
    element3d: 'element3d',

    location: 'location',
    standardTile: STANDARD_TILE,
    polygon: POLYGON,
    customPolygon: CUSTOM_POLYGON,
    locationName: 'location_name',
  },

  drawnGeometryProperties: {
    area: '________area_______',
    longitude: '________longitude_______',
    latitude: '________latitude_______',
    length: '__________length_______',
  },

  parseViewerConfig: (input, useUrl) => {
    const inputParams = new URLSearchParams(input);

    if (useUrl) {
      const urlParams = new URLSearchParams(window.location.search);
      for (let [key, value] of urlParams?.entries()) {
        inputParams.set(key, value);
      }
    }

    const viewerConfig = checkUrlParam(PRESENTATION_MODE, inputParams)
      ? PRESENTATION_MODE_PRESET
      : VIEWER_CONFIG?.reduce((acc, { key, check }) => {
          const active = checkUrlParam(check, inputParams);

          return { ...acc, ...(active !== NOT_IN_URL ? { [key]: !!active } : {}) };
        }, {});

    //hacky thing for 1 customer
    let maxZoom = inputParams.get('maxZoom');
    if (maxZoom) {
      viewerConfig.maxZoom = parseInt(maxZoom);
    }

    let invertHide = checkUrlParam('invertHide', inputParams);

    if (invertHide) {
      let keys = Object.keys(viewerConfig);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'hideSidePane') {
          viewerConfig[keys[i]] = !viewerConfig[keys[i]];
          if (keys[i].includes('Action')) {
            viewerConfig['hideLayerActions'] = false;
          } else if (keys[i].includes('Controls')) {
            viewerConfig['hideLayerControls'] = false;
          }
        }
      }
    }

    return viewerConfig;
  },

  getGeoLocation: async (locationName) => {
    locationName = locationName.replace('#', '');

    const x_split = locationName.split(' ');

    if (x_split.length === 2) {
      const lon = parseFloat(x_split[0]);
      const lat = parseFloat(x_split[1]);
      if (!isNaN(lat) && !isNaN(lon)) {
        if (lat <= 85 && lat >= -85 && lon <= 180 && lon >= -180) {
          return { type: 'point', zoom: 14, point: { x: lon, y: lat } };
        }
      }
    }

    //cool additional feature use proximity option
    // proximity=-74.70850,40.78375

    // let google = await Geocode.fromAddress('Eiffel Tower');

    let request1 = `https://api.mapbox.com/geocoding/v5/mapbox.places/${locationName}.json?access_token=pk.eyJ1IjoiZGFhbjE5OTEiLCJhIjoiY2tjb3h5OHFpMHB3YTMzcGJ3d2hrajVwZSJ9.BSZbQtdYXaM1YA6hVDP_yg`;

    let result1 = fetch(request1).then((result) => {
      return result.json();
    });

    //    let request2 = 'https://nominatim.openstreetmap.org/search?q='.concat(locationName).concat('&format=json');
    //  let result2 = fetch(request2).then((result) => {
    //  return result.json();
    //});

    result1 = await result1;
    //    result2 = await result2;

    //    console.log(result2);

    if (result1?.features?.length > 0) {
      let res = result1.features[0];

      if (res.bbox) {
        let location = res.bbox;
        let bounds = { xMin: location[0], yMin: location[1], xMax: location[2], yMax: location[3] };
        return { type: 'bounds', bounds: bounds };
      } else {
        return { type: 'point', zoom: 14, point: { x: res.center[0], y: res.center[1] } };
      }
    } else {
      return null;
    }
  },
  download: (fileName, text, mime) => {
    if (isMobile && isAndroid) {
      const fileStream = streamSaver.createWriteStream(fileName);

      new Response(text).body.pipeTo(fileStream).then(
        () => {},
        (e) => {
          // console.warn(e);
        }
      );
    } else if (isMobile && isIOS) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          fileName: fileName,
          data: text,
          mime: mime,
        })
      );
    } else {
      let file = new File([text], fileName, { type: `${mime};charset=utf-8}` });
      FileSaver.saveAs(file);
    }
  },

  createGeoJsonLayerStyle: (color, borderColor, fillOpacity, weight) => {
    borderColor = !borderColor ? color : borderColor;
    borderColor = borderColor.length === 7 ? borderColor + 'ff' : borderColor.slice(0, 7) + 'ff';

    return {
      fillColor: color ? color : '#3388ff',
      color: borderColor ? borderColor : '#3388ff',
      weight: weight ? weight : 5,
      fillOpacity: fillOpacity || fillOpacity === 0 ? fillOpacity : 0.06,
    };
  },

  isPrivateProperty: 'isPrivate',

  renderSvg: (input, index = 0) => {
    if (input.type === 'defs') {
      let children = null;
      if (input.props.children.length > 1) {
        children = input.props.children.map((x, i) => ViewerUtility.renderSvg(x, i));
      } else if (input.props.children) {
        children = ViewerUtility.renderSvg(input.props.children);
      }

      return <defs key={'defs_' + index}>{children}</defs>;
    } else if (input.type === 'path') {
      return <path key={'path_' + index} {...input.props}></path>;
    }
  },
};

export default ViewerUtility;
export const drawerWidth = 160,
  smallDrawerWidth = 62;

export const formatDate = (date, range) => {
  const timestampFrom = new Date(range.dateFrom).getTime() / 1000;
  const timestampTo = new Date(range.dateTo).getTime() / 1000;

  const l = timestampTo - timestampFrom;

  if (l / (60 * 60) > 24 * 5) {
    return moment(date).format('L');
  } else if (l / (60 * 60) > 12) {
    return moment(date).format('L LT');
  } else {
    return moment(date).format('L LTS');
  }
};

export const manipulate = (data, invert, cummulative, derivative) => {
  let S = 0;
  let old = null;
  const p = [...data];
  const newData = p
    .sort((a, b) => a.x > b.x)
    .map((x) => {
      let n = { ...x };
      if (invert) {
        n.y = -n.y;
      }
      if (cummulative) {
        S = S + n.y;
        n.y = S;
      }
      if (derivative) {
        if (old) {
          const t1 = new Date(x.x).getTime() / 1000;
          const t2 = new Date(old.x).getTime() / 1000;
          const dy = x.y - old.y;
          const dx = t1 - t2;
          // console.log('dx', dx);
          // console.log('dy', dy);
          old = { y: n.y, x: n.x };
          n.y = dy / dx;
        } else {
          old = n;
          return null;
        }
      }
      n.label = n.y;
      // console.log('N', n);
      return n;
    })
    .filter((x) => x !== null);

  return newData;
};

export const filterProperties = (properties = [], heading = false, layer = null) => {
  if (
    layer?.renderOptions?.properties &&
    layer?.renderOptions?.properties
      .map((x) => x.name)
      .sort()
      .join(',') ===
      properties
        .map((x) => x.name)
        .sort()
        .join(',')
  ) {
    return [
      ...layer.renderOptions?.properties.map((p) => {
        return { ...p };
      }),
    ];
  } else {
    return properties.filter((p) => !p?.deleted).sort((a, b) => a?.name?.localeCompare(b?.name));
  }
};

// export let viewPosition = { bounds: { xMin: -180, xMax: 180, yMin: -85, yMax: 85 }, zoom: 0 };
// This block is purely to get the marker icon of Leaflet to work.
// Taken somewhere from the web.

const paramCheckFunc = (input, params) => {
  const value = params.get(input)?.toLowerCase();
  const checkPositive = ['1', 'true', ''];

  return params.has(input) ? checkPositive?.includes(value) : NOT_IN_URL;
};

const checkUrlParam = (inputs, params) => {
  if (typeof inputs === 'string') {
    return paramCheckFunc(inputs, params);
  } else {
    const checkedArray = inputs?.map((input) => paramCheckFunc(input, params));
    //'automatic' Logical OR
    return checkedArray?.includes(true) ? true : checkedArray?.includes(false) ? false : NOT_IN_URL;
  }
};

const ELLIPSIS_TYPES = ['raster', 'vector', 'pointCloud', 'map', 'shape'];

export const isEllipsisLayer = (l) => !!ELLIPSIS_TYPES?.includes(l?.type);

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const glbToGeojson = async (
  result,
  cb,
  amount = 3000,
  transform = true,
  tile = { zoom: 0, tileX: 0, tileY: 0 }
) => {
  Promise.all([import('three/examples/jsm/loaders/GLTFLoader'), import('proj4')])
    .then(async ([{ GLTFLoader }, { default: proj4 }]) => {
      const loader = new GLTFLoader();
      let arrayBuffer = await result.arrayBuffer();

      const LEN = 2.003751e7;
      const offsetX = (2 * LEN * tile.tileX) / 2 ** tile.zoom - LEN;
      const offsetY = LEN - (2 * LEN * (tile.tileY + 1)) / 2 ** tile.zoom;

      loader.parse(arrayBuffer, '', (gltf) => {
        const features = [];

        let group = gltf.scene.children[0];
        if (group.geometry) {
          group = { children: [group] };
        }

        for (let d = 0; d < group.children.length; d++) {
          let points = group.children[d];
          let geometry = points.geometry;

          let count = Math.min(amount, geometry.attributes.position.count);
          for (let c = 0; c < count * 3; c += 3) {
            let x = geometry.attributes.position.array[c] + offsetX;
            let y = geometry.attributes.position.array[c + 1] + offsetY;
            let z = geometry.attributes.position.array[c + 2];
            let r;
            let g;
            let b;
            if (geometry.attributes.color) {
              r = Math.round(geometry.attributes.color.array[c] * 255);
              g = Math.round(geometry.attributes.color.array[c + 1] * 255);
              b = Math.round(geometry.attributes.color.array[c + 2] * 255);
            } else {
              r = 0;
              b = 0;
              g = 0;
            }

            let color = rgbToHex(r, g, b);

            let coords;

            if (transform) {
              coords = proj4('EPSG:3857', 'EPSG:4326', [x, y]);
            } else {
              coords = [x, y];
            }
            const feature = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [...coords, z],
              },
              properties: {
                color: color,
              },
            };

            features.push(feature);
          }
        }

        const finalResult = {
          type: 'FeatureCollection',
          features: features,
        };
        cb(finalResult);
      });
    })
    .catch((err) => console.error(err));
};

import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { getColor, parseExtension } from 'Drive/FileItem/FilesUtils';
import { Typography } from '@mui/material';

const STANDARD_HEIGHT = 64;

const useFileIconStyle = makeStyles({ name: 'FileIcon' })((theme, { length, size }, classes) => ({
  root: {
    height: size || STANDARD_HEIGHT,
    width: size || STANDARD_HEIGHT,
  },
  text: {
    ...theme.typography.button,
    fontSize: length < 6 ? 10 : length < 8 ? 8 : 6,
    maxWidth: 12,
  },
  round: {
    ...theme.typography.button,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: 1,
    borderRadius: '100%',
    color: 'white',
    fontSize: 10,
    overflowWrap: 'anywhere',
    padding: theme.spacing(0.5),
    boxSizing: 'border-box',
    lineHeight: '100%',
    [`&.${classes.smallerText}`]: { fontSize: 8 },
  },
  smallerText: {},
}));

export const FileIcon = ({ file, type = 'full', size = 0, className }) => {
  const { fileExtension } = parseExtension(file?.name);

  const { classes: styles, cx } = useFileIconStyle({ length: fileExtension?.length, size });
  const color = getColor(fileExtension);

  if (type === 'round') {
    return (
      <Typography
        className={cx(styles.root, styles.round, fileExtension?.length > 8 && styles.smallerText, className)}
        component="div"
        sx={{ backgroundColor: getColor(fileExtension) }}
      >
        {fileExtension && fileExtension?.length < 16 ? fileExtension : 'FILE'}
      </Typography>
    );
  } else {
    return (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx(styles.root, className)}
      >
        <path
          opacity="0.35"
          d="M53.4651 13.7295V58.3993C53.4651 58.7316 53.1949 59 52.8604 59H11.1419C10.8074 59 10.5372 58.7316 10.5372 58.3993V5.36426C10.5339 5.16615 10.6948 5 10.8974 5H44.7959L53.4651 13.7295Z"
          fill={color}
        />
        <path
          opacity="0.67"
          d="M53.4657 13.7288C50.9823 13.7288 46.363 13.7288 45.1117 13.7288C44.9348 13.7288 44.79 13.585 44.79 13.4092L44.7965 4.99609C47.6851 7.90698 50.5738 10.8147 53.4625 13.7256L53.4657 13.7288Z"
          fill={color}
        />

        <rect x="9" y="27" width="46" height="18" rx="2" fill={color} />
        <text textAnchor="middle" x="32" y={fileExtension?.length < 8 ? 39 : 38} fill="white" className={styles.text}>
          {!!fileExtension ? fileExtension : 'FILE'}
        </text>
      </svg>
    );
  }
};

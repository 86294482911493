import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';

// import mergeWith from 'lodash/mergeWith';

import { useMinimalAuth } from 'hooks';

import { isValidMap, isValidTimestamp } from 'ReusableComponents/ValidLayer/ValidLayer';

import ApiManager from 'ApiManager';

import VersionUtility from 'VersionUtility';

const layersContext = createContext();

const isMapLayer = (layer) => {
  return layer.type === 'map';
};

const isShapeLayer = (layer) => {
  return layer.type === 'shape';
};

const isExternalLayer = (layer) => {
  return ['wms', 'wmts', 'wfs', 'buildings', 'vectorTiles'].includes(layer.type);
};

export const defaultPreviewObject = (layer) => {
  let timestamps = layer.timestamps.filter((t) => t.totalSize > 0);

  return {
    timestampRange: {
      timestamps: timestamps,
      start: Math.max(0, timestamps.length - 1),
      end: Math.max(0, timestamps.length - 1),
    },
    bounds: {},
  };
};

export const defaultInfoObject = (newLayer) => {
  if (isExternalLayer(newLayer)) {
    return {};
  }
  let res;
  const timestamps = newLayer.timestamps.filter((timestamp) => isValidTimestamp(timestamp).available);
  let i = timestamps.length - 1;
  for (let j = 0; j < timestamps.length; j++) {
    if (timestamps[j].totalSize > timestamps[i].totalSize) {
      i = j;
    }
  }

  res = {
    timestampRange: {
      start: i,
      end: i,
      range: false,
      timestamps: timestamps,
    },
  };

  if (['map', 'shape'].includes(newLayer.type)) {
    res.styles = newLayer.styles.map((s, i) => {
      s.tempId = i;
      s.version = 1;
      s.custom = false;
      return s;
    });
    res.style = newLayer.styles.find((x) => x.default);
  }

  if (newLayer.type === 'shape') {
    // console.log('renderOptions', newLayer.vector.renderOptions);
    res = {
      ...res,
      filter: [],
      clientFilter: [],
      location: newLayer.vector.renderOptions?.centerPointOnly,
      zOffset: 0,
      uploads: false,
      type: 'shape',
      tilePyramid:
        timestamps.length === 0
          ? {}
          : {
              zoom: newLayer.vector.renderOptions?.parameters.zoom
                ? newLayer.vector.renderOptions?.parameters.zoom
                : res.timestampRange.timestamps[res.timestampRange.timestamps.length - 1].statistics?.precomputed &&
                  res.timestampRange.timestamps[res.timestampRange.timestamps.length - 1].statistics?.count <= 500000
                ? res.timestampRange.timestamps[res.timestampRange.timestamps.length - 1].statistics.precomputeZoom
                : res.timestampRange.timestamps[res.timestampRange.timestamps.length - 1].statistics?.zoom
                ? res.timestampRange.timestamps[res.timestampRange.timestamps.length - 1].statistics.zoom
                : 16,
              updated: false,
            },
    };

    if (res.tilePyramid.zoom > 16) {
      res.tilePyramid.zoom = 16;
    }
  } else if (newLayer.type === 'pointCloud') {
    res = { ...res, tilePyramid: {} };
  }

  return res;
};

const useSelectedGeometryLayers = (_setLayers) => {
  const setGeometryInfo = (id, key, value, overRideStyleId = null) => {
    _setLayers((prevLayers) => {
      if (key === 'style') {
        if (!value.tempId && value.tempId !== 0) {
          const tempIds = prevLayers.layersInfo[id].styles.map((s) => s.tempId);
          value.tempId = Math.max.apply(Math, tempIds) + 1;
        }
        let newStyles = prevLayers.layersInfo[id].styles.map((s) => {
          if (s.tempId === value.tempId) {
            return value;
          }
          return s;
        });
        return {
          ...prevLayers,
          layersInfo: {
            ...prevLayers.layersInfo,
            [id]: { ...prevLayers.layersInfo[id], [key]: value, styles: newStyles },
          },
        };
      } else if (key === 'styles') {
        let newStyle;
        if (overRideStyleId) {
          newStyle = value.find((s) => s.tempId === overRideStyleId);
        } else {
          newStyle = value.find((s) => s.tempId === prevLayers.layersInfo[id].style.tempId);
        }
        if (!newStyle) {
          newStyle = value[0];
        }

        return {
          ...prevLayers,
          layersInfo: {
            ...prevLayers.layersInfo,
            [id]: { ...prevLayers.layersInfo[id], [key]: value, styles: value, style: newStyle },
          },
        };
      } else {
        return {
          ...prevLayers,
          layersInfo: { ...prevLayers.layersInfo, [id]: { ...prevLayers.layersInfo[id], [key]: value } },
        };
      }
    });
  };

  return [setGeometryInfo];
};

const useToggleLayer = (_setLayers) => {
  const toggleLayer = useCallback(
    (layer) => {
      _setLayers((prevLayers) => {
        return {
          ...prevLayers,
          layers: prevLayers.layers.map((prevLayer) =>
            prevLayer.id === layer.id ? { ...prevLayer, isSelected: !prevLayer.isSelected } : prevLayer
          ),
        };
      });
    },
    [_setLayers]
  );

  return toggleLayer;
};

const useAddLayer = (_setLayers, layers) => {
  const addLayer = (layer, onFlyTo) => {
    let info = defaultInfoObject(layer);

    if (!layers.layersInfo[layer.id]) {
      _setLayers((prevLayers) => {
        const newObj = {
          key: prevLayers.key,
          layersInfo: { ...prevLayers.layersInfo, [layer.id]: info },
          layers: [{ ...layer, isSelected: true }, ...prevLayers.layers],
        };
        return newObj;
      });
    } else {
      _setLayers((prevLayers) => {
        return { ...prevLayers, layers: [{ ...layer, isSelected: true }, ...prevLayers.layers] };
      });
    }
    if (onFlyTo) {
      onFlyTo({ type: 'bounds', bounds: info.timestampRange.timestamps[info.timestampRange.end].extent });
    }
  };
  return addLayer;
};

const useRemoveLayer = (_setLayers) => {
  const removeLayer = useCallback(
    (layer) => {
      _setLayers((prevLayers) => {
        const newLayersInfo = prevLayers.layersInfo;
        delete newLayersInfo[layer.id];
        return {
          ...prevLayers,
          layersInfo: newLayersInfo,
          layers: prevLayers.layers.filter((prevLayer) => prevLayer.id !== layer.id),
        };
      });
    },
    [_setLayers]
  );

  return removeLayer;
};

const useReorderLayers = (setLayers) => {
  const reorderLayers = useCallback(
    (destinationIndex, sourceIndex) => {
      setLayers((prevLayers) => {
        const newLayers = [...prevLayers.layers];
        const [removed] = newLayers.splice(sourceIndex, 1);
        newLayers.splice(destinationIndex, 0, removed);

        return { ...prevLayers, layers: newLayers };
      });
    },
    [setLayers]
  );

  return reorderLayers;
};

export const updateCustomiser = (objectValue, srcValue, key) => {
  if ((key === 'features' || key === 'tiles') && objectValue?.length !== srcValue?.length) {
    return srcValue;
  }
};

const useUpdateLayer = (_setLayers) => {
  const updateLayer = useCallback(
    (newLayer, force = false) =>
      _setLayers(({ layersInfo, layers, ...prevLayers }) => ({
        ...prevLayers,
        layers: layers.map((layer) => (layer.id === newLayer.id ? { ...layer, ...newLayer } : layer)),
        layersInfo: !!force ? { ...layersInfo, [newLayer.id]: defaultInfoObject(newLayer) } : layersInfo,
      })),
    [_setLayers]
  );

  return updateLayer;
};

const useLoadLayersState = (_setLayers, cachedLayers) => {
  const loadLayersState = useCallback(
    ({ layers, layersInfo, key }) => {
      _setLayers({ layers: layers, layersInfo: layersInfo, key: key });
      cachedLayers.current = layers;
    },
    [_setLayers, cachedLayers]
  );

  return loadLayersState;
};

const usePreviewLayers = (setPreviewLayers) => {
  const addPreviewLayer = (layer) =>
    setPreviewLayers((prevLayers) => {
      let info = prevLayers.previewLayersInfo[layer.id]
        ? prevLayers.previewLayersInfo[layer.id]
        : defaultPreviewObject(layer);

      return {
        previewLayersInfo: { ...prevLayers.previewLayersInfo, [layer.id]: info },
        previewLayers: [layer, ...prevLayers.previewLayers],
      };
    });
  const removePreviewLayer = (layer) => {
    setPreviewLayers((prevLayers) => {
      return { ...prevLayers, previewLayers: prevLayers.previewLayers.filter((l) => l.id !== layer.id) };
    });
  };

  const setPreviewLayerInfo = (layer, key, value) => {
    setPreviewLayers((prevLayers) => {
      return {
        ...prevLayers,
        previewLayersInfo: {
          ...prevLayers.previewLayersInfo,
          [layer.id]: {
            ...prevLayers.previewLayersInfo[layer.id],
            [layer.id]: { ...prevLayers.previewLayersInfo[layer.id], key: value },
          },
        },
      };
    });
  };

  const setPreviewBounds = async (layer, timestampId, user) => {
    let geometryBounds;
    //    try {
    geometryBounds = await ApiManager.get(
      `/v3/path/${layer.id}/${layer.type === 'map' ? 'raster' : 'vector'}/timestamp/${timestampId}/bounds`,
      null,
      user
    );
    //  } catch (e) {
    //   console.error(e);
    // }
    setPreviewLayers((prevLayers) => {
      const newObj = {
        ...prevLayers,
        previewLayersInfo: {
          ...prevLayers.previewLayersInfo,
          [layer.id]: {
            ...prevLayers.previewLayersInfo[layer.id],
            bounds: { ...prevLayers.previewLayersInfo[layer.id].bounds, [timestampId]: geometryBounds },
          },
        },
      };
      return newObj;
    });
  };

  return [addPreviewLayer, removePreviewLayer, setPreviewLayerInfo, setPreviewBounds];
};

export const LayersProvider = ({ children }) => {
  //main state consists of a layers and layersInfo
  const [layers, _setLayers] = useState({ layers: [], layersInfo: {}, key: 0 });

  const [previewLayers, setPreviewLayers] = useState({ previewLayersInfo: {}, previewLayers: [] });
  const [searchBounds, setSearchBounds] = useState([]);
  const cachedLayers = useRef([]);
  const user = useMinimalAuth();

  const selectedLayers = useMemo(() => layers.layers.filter((layer) => layer.isSelected), [layers]);
  const selectedMapLayers = useMemo(() => selectedLayers.filter((layer) => isMapLayer(layer)), [selectedLayers]);
  const selectedGeometryLayers = useMemo(() => selectedLayers.filter((layer) => isShapeLayer(layer)), [selectedLayers]);
  const selectedExternalLayers = useMemo(
    () => selectedLayers.filter((layer) => isExternalLayer(layer)),
    [selectedLayers]
  );

  const accessLevel = useMemo(() => {
    return layers?.layers
      ?.map((l) => l?.yourAccess?.accessLevel)
      .filter((l) => !!l)
      .reduce(
        ({ min, max, average }, c, i, { length }) =>
          i < length - 1
            ? { min: c < min ? c : min, average: average + c, max: c > max ? c : max }
            : { min: c < min ? c : min, average: (average + c) / length, max: c > max ? c : max },
        { min: 0, max: 0, average: 0 }
      );
  }, [layers?.layers]);

  //core functions on the layers and layersinfo
  const reorderLayers = useReorderLayers(_setLayers);
  const [setGeometryInfo] = useSelectedGeometryLayers(_setLayers);
  const addLayer = useAddLayer(_setLayers, layers);
  const removeLayer = useRemoveLayer(_setLayers);
  const toggleLayer = useToggleLayer(_setLayers);
  const updateLayer = useUpdateLayer(_setLayers);
  const loadLayersState = useLoadLayersState(_setLayers, cachedLayers);
  const [addPreviewLayer, removePreviewLayer, setPreviewLayerInfo, setPreviewBounds] =
    usePreviewLayers(setPreviewLayers);

  const invalidLayers = useMemo(() => layers.layers.filter((l) => !isValidMap(l).available), [layers]);

  useEffect(() => {
    const interval = setInterval(() => {
      for (let i = 0; i < invalidLayers.length; i++) {
        const l = invalidLayers[i];

        ApiManager.get(`/v3/path/${l.id}`, null, user)
          .then((res) => {
            res = VersionUtility.convertPathInfo(res);

            updateLayer(res, true);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [invalidLayers, updateLayer, user]);

  const value = useMemo(
    () => ({
      accessLevel,
      layers: layers.layers,
      cachedLayers,
      reorderLayers,
      toggleLayer,
      addLayer,
      removeLayer,
      layersKey: layers.key,
      updateLayer,
      loadLayersState,
      addPreviewLayer,
      setPreviewLayerInfo,
      removePreviewLayer,
      selectedLayers,
      selectedMapLayers,
      selectedGeometryLayers,
      selectedExternalLayers,
      previewLayersInfo: previewLayers.previewLayersInfo,
      previewLayers: previewLayers.previewLayers,
      setGeometryInfo,
      searchBounds,
      setSearchBounds,
      setLayersInfo: setGeometryInfo,
      layersInfo: layers.layersInfo,
      setPreviewBounds,
    }),
    [
      accessLevel,
      layers,
      previewLayers,
      searchBounds,
      setSearchBounds,
      cachedLayers,
      reorderLayers,
      toggleLayer,
      addLayer,
      removeLayer,
      updateLayer,
      loadLayersState,
      addPreviewLayer,
      setPreviewLayerInfo,
      removePreviewLayer,
      selectedLayers,
      selectedMapLayers,
      selectedGeometryLayers,
      selectedExternalLayers,
      setGeometryInfo,
      setPreviewBounds,
    ]
  );

  return <layersContext.Provider value={value}>{children}</layersContext.Provider>;
};

export function useLayers(props) {
  const { suppressError = false } = { ...props };

  const context = useContext(layersContext);
  if (context === undefined && !suppressError) {
    throw new Error('useLayers must be used within a LayersProvider');
  }
  return context || (!!suppressError && {});
}

export function withLayers(Component) {
  return function WrapperComponent(props) {
    const context = useLayers();
    return <Component {...props} {...context} />;
  };
}

export function withLayersContext(Component) {
  return function WrapperComponent(props) {
    return (
      <LayersProvider>
        <Component {...props} />
      </LayersProvider>
    );
  };
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Tooltip, useMediaQuery } from '@mui/material';
import { Bookmark, BookmarkAdded, ExitToApp, Group, Link } from '@mui/icons-material';

import SaveDialog from './SaveDialog/SaveDialog';

import ManageSharingDialog from 'ReusableComponents/ManageSharingDialog';
import StyledGroupButton from 'ReusableComponents/StyledGroupButton';

import { useMinimalAuth } from 'hooks';

import debounce from 'lodash/debounce';
import ApiManager from 'ApiManager';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMainViewer } from 'Viewer/ViewerContexts/MainViewerContext';
import { useViewerFuncs } from 'Viewer/ViewerContexts/ViewerFuncsContext';

const INIT_NUMBER = 0;
const MAX_NUMBER = 1;

const useNavbarStyles = makeStyles({ name: 'NavbarViewerButton' })((theme, _params, classes) => ({
  button: {
    borderRadius: 0,
    padding: theme.spacing(0.5, 1.5),
    [`&.${classes.icon}`]: {
      padding: theme.spacing(0.5),
      '&&': { minWidth: 'unset' },
      [theme.breakpoints.down('sm')]: { '&>svg': { width: 24, height: 24 } },
    },
  },
  root: { marginRight: theme.spacing(1) },
  placeholder: { opacity: 0, pointerEvents: 'none', position: 'static' },
  real: {
    position: 'fixed',
    zIndex: 1300,
    transition: theme.transitions.create(['left', 'top', 'width']),
    backgroundColor: theme.palette.primary.main,
  },
  icon: {},
  showEditTooltip: {
    opacity: 0.5,
    zIndex: 1,
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(20),
    '&:hover': { opacity: 1 },
    transition: theme.transitions.create('opacity'),
    [theme.breakpoints.down('sm')]: { right: theme.spacing(10) },
  },
  showEditTooltipX: {
    opacity: 1,
    zIndex: 1,
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(20),
    '&:hover': { opacity: 1 },
    transition: theme.transitions.create('opacity'),
    [theme.breakpoints.down('sm')]: { right: theme.spacing(10) },
  },
  showEdit: { position: 'unset' },
}));

const NavbarViewerButton = ({ isNavbar }) => {
  const { currentFolderInfo: selectedMap } = useCurrentFolder();
  const { viewerConfig } = useMainViewer();
  const { handleCreateShareLink } = useViewerFuncs();
  const { classes: styles, cx } = useNavbarStyles();
  const maxSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { changeMainModeAndExit } = useCurrentFolder();
  const [windowWidth, setWindowWidth] = useState(INIT_NUMBER);

  const [openSharing, setOpenSharing] = useState(false);
  const sharingOpen = useCallback(() => setOpenSharing(true), []);

  const user = useMinimalAuth();

  const [openSave, setOpenSave] = useState(false);

  const saveClose = useCallback(() => {
    setOpenSave(false);
  }, []);

  const [openTooltip, setOpenTooltip] = useState(false);
  const [canOpenTooltip, setCanOpenTooltip] = useState(true);
  const handleSetOpenCb = useCallback((input) => {
    setOpenTooltip(false);
    setCanOpenTooltip(!input);
  }, []);
  const tooltipClose = useCallback((e) => setOpenTooltip(false), []);
  const tooltipOpen = useCallback((e) => setOpenTooltip(true), []);

  const exit = useCallback(() => {
    changeMainModeAndExit('drive');
  }, [changeMainModeAndExit]);

  const isPlaceholder = useMemo(() => !handleCreateShareLink, [handleCreateShareLink]);

  let isShowEdit;
  try {
    isShowEdit = !!viewerConfig?.hideNavbar && window.location?.href === window.parent.location?.href;
  } catch {
    isShowEdit = false;
  }

  const actions = useMemo(
    () => [
      {
        type: 'share',
        icon: <Group />,
        onClick: sharingOpen,
        label: 'Share',
      },
      ...(isShowEdit
        ? [
            {
              type: 'exit',
              icon: <ExitToApp />,
              onClick: exit,
              label: 'Exit bookmark',
            },
          ]
        : []),
      {
        type: 'quickLink',
        icon: <Link />,
        onClick: () => handleCreateShareLink(),
        label: 'Quick link',
        shortLabel: 'Copy link',
      },
      ...(user ? [{ type: 'subheader', label: 'Bookmark', category: 'bookmark' }] : []),
      ...(user &&
      selectedMap?.type === 'bookmark' &&
      selectedMap?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+']
        ? [
            {
              type: 'update',
              icon: <BookmarkAdded />,
              onClick: () => setOpenSave({ isUpdate: true }),
              label: 'Update bookmark',
              category: 'bookmark',
            },
          ]
        : []),
      ...(user
        ? [
            {
              type: 'saveAs',
              icon: <Bookmark />,
              onClick: () => setOpenSave({ isUpdate: false }),
              label: 'Save As Bookmark',
              category: 'bookmark',
            },
          ]
        : []),
    ],
    [handleCreateShareLink, selectedMap?.yourAccess.accessLevel, sharingOpen, exit, selectedMap?.type, user]
  );

  const button = useMemo(
    () => (
      <StyledGroupButton
        key={windowWidth}
        actions={actions}
        buttonGroupProps={{
          variant: 'contained',
        }}
        buttonProps={{
          variant: 'contained',
          className: cx(styles.button, maxSm && styles.icon),
        }}
        defaultActionProps={{
          index: undefined,
          startIcon: false,
          labelProp: maxSm ? 'icon' : 'label',
        }}
        menuProps={{
          slotProps: { root: { sx: { zIndex: 1300 } }, backdrop: { sx: { background: 'rgba(0, 0, 0, 0.5)' } } },
        }}
        openCb={handleSetOpenCb}
      />
    ),
    [actions, cx, handleSetOpenCb, maxSm, styles.button, styles.icon, windowWidth]
  );

  const debounceSet = useMemo(
    () => debounce(() => setWindowWidth((old) => (old > MAX_NUMBER ? INIT_NUMBER : old + 1)), 100, { maxWait: 500 }),
    []
  );

  const resizeObserver = useMemo(() => new ResizeObserver(debounceSet), [debounceSet]);
  const bodyElement = useMemo(() => document.getElementsByTagName('body')?.[0], []);

  useEffect(() => {
    if (!isPlaceholder) {
      resizeObserver.observe(bodyElement);
      return () => resizeObserver.unobserve(bodyElement);
    }
  }, [bodyElement, isPlaceholder, resizeObserver]);

  return (
    <>
      <Tooltip
        title={'Actions'}
        className={isNavbar ? styles.showEditTooltipX : styles.showEditTooltip}
        open={canOpenTooltip && openTooltip}
        onClose={tooltipClose}
        onOpen={tooltipOpen}
        disableInteractive
      >
        <span>{button}</span>
      </Tooltip>
      {!isPlaceholder && <ManageSharingDialog open={openSharing} openFunc={setOpenSharing} actions={actions} />}
      {!isPlaceholder && (
        <SaveDialog
          open={!!openSave}
          selectedMap={selectedMap}
          handleCreateShareLink={handleCreateShareLink}
          handleClose={saveClose}
          isUpdate={openSave?.isUpdate}
          viewerConfig={viewerConfig}
        />
      )}
    </>
  );
};

export default NavbarViewerButton;

import React, { Component, useCallback, useEffect, useRef, useState } from 'react';

import { Clear } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Typography } from '@mui/material';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { ButtonControlsWrapper, MapControls, UserInfoBox } from './GeofenceBlocks';
import { getBoundsToTiles, tileToFeature } from './GeofenceBlocks/map-helper';
import { isValidTimestamp } from 'ReusableComponents/ValidLayer';

import { withMainContext } from 'ReusableComponents/MainContext';
import { useMinimalAuth } from 'hooks';

import ApiManager from 'ApiManager';
import './Geofence.css';
import { useTheme } from '@mui/system';
import { glbToGeojson } from 'Viewer/ViewerUtility';

const MAP_ZOOM = 24;

const MapComponent = ({ setTiles, setZoom, timestamp, selectedMap, addToChosen, fenceTiles, chosenTiles }) => {
  const theme = useTheme();
  const user = useMinimalAuth();
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [styleSheet, setStyleSheet] = useState(null);

  const [mapLoaded, setMapLoaded] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (map.current && map.current.isStyleLoaded()) {
        setMapLoaded(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once
    map.current = new maplibregl.Map({
      renderWorldCopies: false,
      container: mapContainer.current,
      style: {
        version: 8,
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        sources: {
          osm: {
            type: 'raster',
            tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
            maxzoom: 18,
            tileSize: 256,
          },
          tiles: {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] },
          },
          selectedTiles: {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] },
          },
        },
        layers: [
          {
            id: 'osm',
            type: 'raster',
            source: 'osm',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
          {
            id: 'tiles-fill',
            type: 'fill',
            paint: { 'fill-opacity': 0, 'fill-color': 'blue' },
            layout: {
              visibility: 'visible',
            },
            source: 'tiles',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },

          {
            id: 'tiles',
            type: 'line',
            paint: { 'line-width': 2, 'line-opacity': 1, 'line-color': theme.palette.common.gray3 },
            layout: {
              visibility: 'visible',
            },
            source: 'tiles',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
          {
            id: 'selectedTiles',
            type: 'fill',
            paint: { 'fill-opacity': 0.5, 'fill-color': theme.palette.common.gray1 },
            layout: {
              visibility: 'visible',
            },
            source: 'selectedTiles',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
        ],
      },
      center: [139.753, 35.6844],
      zoom: 14,
      maxZoom: MAP_ZOOM,
      maxPitch: 0,
    });

    map.current.on('click', 'tiles-fill', function (e) {
      addToChosen(e.features[0]['_vectorTileFeature']);
    });

    map.current.on('mouseenter', 'tiles-fill', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', 'tiles-fill', () => {
      map.current.getCanvas().style.cursor = 'default';
    });
    map.current.scrollZoom.disable();
    map.current.addControl(new maplibregl.NavigationControl());
  }, [addToChosen]);

  useEffect(() => {
    return () => {
      map?.current?.remove();
    };
  }, []);

  //fly to
  const prevTimestampId = useRef(-1);
  useEffect(() => {
    if (!timestamp) return;
    if (prevTimestampId.current !== timestamp?.id) {
      prevTimestampId.current = timestamp?.id;
      const bounds = timestamp.extent ? timestamp.extent : { xMin: -180, xMax: 180, yMin: -85, yMax: 85 };
      map.current.fitBounds(
        [
          [bounds.xMin, bounds.yMin],
          [bounds.xMax, bounds.yMax],
        ],
        { duration: 0.01 }
      );
    }
  }, [map, timestamp]);

  const getStyleSheet = useCallback(() => {
    let url = `/v3/ogc/mvt/${selectedMap.id}/styleSheet?style=${selectedMap.styles[0].id}&zoom=${
      timestamp.zoom ? timestamp.zoom : 16
    }&timestampId=${timestamp.id}`;

    if (user) {
      url = url + '&token=' + user.token;
    }

    ApiManager.get(url).then((res) => {
      setStyleSheet(res);
    });
  }, [user, selectedMap, timestamp]);

  const addRaster = useCallback(
    (sourceId) => {
      let url = `${ApiManager.apiUrl}/v3/path/${selectedMap.id}/raster/timestamp/${timestamp.id}/tile/{z}/{x}/{y}?style=${selectedMap.styles[0].id}`;
      if (user) {
        url = url + '&token=' + user.token;
      }
      if (!map.current.getSource(sourceId)) {
        map.current.addSource(sourceId, {
          type: 'raster',
          tiles: [url],
          maxzoom: MAP_ZOOM,
        });
      }
      if (currentLoad.current) {
        map.current.removeLayer(currentLoad.current);
      }

      map.current.addLayer({
        id: sourceId,
        type: 'raster',
        source: sourceId,
        minzoom: 0,
        maxzoom: MAP_ZOOM,
      });

      map.current.moveLayer(sourceId, 'selectedTiles');
      map.current.moveLayer(sourceId, 'tiles');
      map.current.moveLayer(sourceId, 'tiles-fill');

      currentLoad.current = sourceId;
    },
    [selectedMap, timestamp]
  );

  const addedLayers = useRef([]);

  const onlyOnce = useRef(false);
  const addPointCloud = useCallback(
    (sourceId) => {
      if (onlyOnce.current) {
        return;
      }

      onlyOnce.current = true;
      const url = `/v3/path/${selectedMap.id}/pointCloud/timestamp/${timestamp.id}/tile/0/0/0`;
      ApiManager.get(url, null, user).then((res) => {
        console.log('here');

        const cb = (finalResult) => {
          map.current.addSource(sourceId, {
            type: 'geojson',
            data: finalResult,
          });

          map.current.addLayer({
            source: sourceId,
            id: timestamp.id,
            type: 'circle',
            paint: {
              'circle-radius': 5,
              'circle-color': ['get', 'color'],
            },
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          });
        };

        glbToGeojson(res, cb, 6000);
      });
    },
    [selectedMap.id, timestamp.id, user]
  );

  const addVector = useCallback(
    (sourceId) => {
      const zoom = timestamp.zoom;
      let url = `${ApiManager.apiUrl}/v3/ogc/mvt/${selectedMap.id}/{z}/{x}/{y}?style=${selectedMap.styles[0].id}&timestampId=${timestamp.id}`;

      if (user) {
        url = url + '&token=' + user.token;
      }

      if (!map.current.getSource(sourceId)) {
        map.current.addSource(sourceId, {
          type: 'vector',
          tiles: [url],
          maxzoom: zoom,
        });
      }

      for (let i = 0; i < addedLayers.current.length; i++) {
        map.current.removeLayer(addedLayers.current[i]);
      }
      addedLayers.current = [];

      for (let i = 0; i < styleSheet.layers.length; i++) {
        const load = styleSheet.layers[i];
        map.current.addLayer({
          ...load,
          id: sourceId + '_' + load.id,
          source: sourceId,
          maxzoom: MAP_ZOOM,
        });
        map.current.moveLayer(sourceId + '_' + load.id, 'selectedTiles');
        map.current.moveLayer(sourceId + '_' + load.id, 'tiles');
        map.current.moveLayer(sourceId + '_' + load.id, 'tiles-fill');
        addedLayers.current.push(sourceId + '_' + load.id);
      }
    },
    [selectedMap, timestamp, user, styleSheet]
  );

  //add layer
  const currentLoad = useRef(null);
  useEffect(() => {
    if (!timestamp) return;
    const sourceId = selectedMap.id + '_' + timestamp.id;

    if (!mapLoaded) return;

    if (currentLoad.current === sourceId) return;

    if (selectedMap.type === 'map') {
      addRaster(sourceId);
    } else if (selectedMap.type === 'shape') {
      if (styleSheet) {
        addVector(sourceId);
      } else {
        getStyleSheet();
      }
    } else if (selectedMap.type === 'pointCloud') {
      addPointCloud(sourceId);
    }
  }, [selectedMap, user, timestamp, mapLoaded, addRaster, addVector, addPointCloud, getStyleSheet, styleSheet]);

  const getFenceTiles = useCallback(
    (bounds, zoom) => {
      let tiles = getBoundsToTiles(bounds, zoom + 1);
      setTiles(tiles);
    },
    [setTiles]
  );

  useEffect(() => {
    if (!mapLoaded) return;
    //click
    map.current.getSource('tiles').setData({
      type: 'FeatureCollection',
      features: fenceTiles,
    });
  }, [fenceTiles, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) return;
    //click
    console.log('chosen tiles', chosenTiles);
    map.current.getSource('selectedTiles').setData({
      type: 'FeatureCollection',
      features: chosenTiles,
    });
  }, [chosenTiles, mapLoaded]);

  const prevBounds = useRef(null);
  const onViewportChanged = useCallback(
    async (bounds, zoom) => {
      if (prevBounds.current !== JSON.stringify(bounds)) {
        prevBounds.current = JSON.stringify(bounds);
        getFenceTiles(bounds, zoom);
        setZoom(zoom);
      }
    },
    [getFenceTiles, setZoom]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (mapLoaded) {
        const canvas = map.current.getCanvas();
        const clientHeight = canvas.clientHeight;
        const clientWidth = canvas.clientWidth;

        const cUL = map.current.unproject([0, 0]).toArray();
        const cUR = map.current.unproject([clientWidth, 0]).toArray();
        const cLR = map.current.unproject([clientWidth, clientHeight]).toArray();
        const cLL = map.current.unproject([0, clientHeight]).toArray();
        const xs = [cUL[0], cUR[0], cLR[0], cLL[0]];
        const ys = [cUL[1], cUR[1], cLR[1], cLL[1]];
        const bounds = { xMin: Math.min(...xs), xMax: Math.max(...xs), yMin: Math.min(...ys), yMax: Math.max(...ys) };
        const zoom = Math.round(map.current.getZoom());

        onViewportChanged(bounds, zoom);
      }
    }, 300);

    return () => clearInterval(interval);
  }, [onViewportChanged, mapLoaded]);

  return (
    <div
      style={{
        width: '100%',
        'max-width': '100%',
        'max-height': '100%',
        height: '500px',
        bottom: '0px',
        right: '0px',
        'transition-property': 'max-width, max-height',
        'transition-duration': '500ms',
        'transition-timing-function': 'cubic-bezier(0.4, 0, 0.2, 1)',
        'transition-delay': '0ms',
      }}
    >
      <div className="map-wrap">
        <div ref={mapContainer} className="map" />
      </div>
    </div>
  );
};

class Geofence extends Component {
  constructor(props, context) {
    super(props, context);
    console.log('props', this.props);
    this.state = {
      selectedTimestamp: this.props.map.timestamps.filter((t) => isValidTimestamp(t).available).length - 1,
      selectedStyleId: this.props.map.styles ? this.props.map.styles[0]?.id : null,
      chosenTiles: this.props.tiles,
      fenceTiles: [],
      getVectorChagned: 0,
      maxZoom: this.props.maxZoom,
      canSubmit: false,
      currentZoom: 0,
    };
    this.setNewViewportTimer = null;

    this.geometryLayer = { tiles: {} };
  }

  componentDidMount() {
    const tiles = this.props.tiles;
    const maxZoom = this.props.maxZoom;
    const chosenTiles = tiles?.map((t) => tileToFeature(t.tileX, t.tileY, t.zoom)) ?? [];
    this.setState({ chosenTiles, maxZoom });

    if (this.props.map.type === 'map' && this.props.map.timestamps.filter((t) => t.status === 'finished').length > 0) {
      this.setState({ selectedTimestamp: 0 });
    }
  }

  updateTimestamp = (e, val) => {
    this.setState({ selectedTimestamp: val });
  };

  setTiles = (tiles) => {
    this.setState({ fenceTiles: tiles });
  };

  setZoom = (zoom) => {
    this.setState({ currentZoom: zoom });
  };

  addToChosen = (tile) => {
    this.setState({ canSubmit: true });
    let tiles = [...this.state.chosenTiles];

    let found = tiles.find(
      (t) =>
        t.properties.tileX === tile.properties.tileX &&
        t.properties.tileY === tile.properties.tileY &&
        t.properties.zoom === tile.properties.zoom
    );

    if (found) {
      tiles = tiles.filter(
        (t) =>
          !(
            t.properties.tileX === tile.properties.tileX &&
            t.properties.tileY === tile.properties.tileY &&
            t.properties.zoom === tile.properties.zoom
          )
      );
    } else {
      if (tiles.length >= 8) {
        this.props.onOpenSnackbar({
          level: 'error',
          content: 'You can use at most 8 tiles',
        });
      } else {
        tiles = tiles.filter((t) => {
          if (t.properties.zoom > tile.properties.zoom) {
            let zoomDifference = t.properties.zoom - tile.properties.zoom;
            if (
              Math.floor(t.properties.tileX / 2 ** zoomDifference) === tile.properties.tileX &&
              Math.floor(t.properties.tileY / 2 ** zoomDifference) === tile.properties.tileY
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
        tiles.push(tile);
      }
    }

    tiles = tiles.map((t) => {
      return tileToFeature(t.properties.tileX, t.properties.tileY, t.properties.zoom);
    });
    this.setState({ chosenTiles: tiles });
  };

  onSubmit = (fenceZoom, fenceTiles) => {
    fenceTiles = fenceTiles.map((t) => {
      return { tileX: t.properties.tileX, tileY: t.properties.tileY, zoom: t.properties.zoom };
    });

    if (this.props.isSearch) {
      if (fenceZoom && this.props.map.type === 'map') {
        this.props.updateMaxZoom(fenceZoom);
      } else {
        this.props.updateMaxZoom(null);
      }
      if (fenceTiles.length > 0) {
        this.props.updateTiles(fenceTiles);
      } else {
        this.props.updateTiles(null);
      }
    } else {
      let body = {
        /* pathId: this.props.map.id */
      };

      if (!this.props.isPublic) {
        body.userId = this.props.targetUser.user.id;
      }
      if (fenceTiles.length === 0) {
        body.removeTiles = true;
      } else {
        body.tiles = fenceTiles;
      }

      if (fenceZoom && this.props.map.type === 'map') {
        body.maxZoom = fenceZoom;
      } else if (this.props.map.type === 'map') {
        body.removeMaxZoom = true;
      }

      if (this.props.isPublic) {
        this.props.updatePublic(body);
      } else {
        this.props.updateUser(body);
      }
    }

    this.props.onCancel();
  };

  setChecked = (id) => {
    this.setState({ selectedStyleId: id });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={'sm'}
        id="GeoFence"
        PaperProps={{
          onClick: (e) => e?.stopPropagation(),
        }}
        onClose={this.props.onCancel}
      >
        <div className="dialog-title">
          <DialogTitle>Geofence</DialogTitle>
          <IconButton onClick={this.props.onCancel} className="button-close" size="large">
            <Clear />
          </IconButton>
        </div>
        <DialogContent className="dialog-wrapper">
          <Typography variant="body1" align="left" style={{ padding: '0 20px' }}>
            Select the area of the map that you would like to share by clicking into the tiles below. You can select
            until eight tiles.
          </Typography>

          <MapControls
            updateTimestamp={this.updateTimestamp}
            timestampIndex={this.state.selectedTimestamp}
            mapType={this.props.map.type}
            map={this.props.map}
            tiles={this.state.tiles}
            layers={this.props.map.mapLayers || this.props.map.geometryLayers}
            selectedStyleId={this.state.selectedStyleId}
            setChecked={(id) => this.setChecked(id)}
          />

          <div className="map-wrapper">
            <MapComponent
              setTiles={this.setTiles}
              setZoom={this.setZoom}
              fenceTiles={this.state.fenceTiles}
              addToChosen={this.addToChosen}
              chosenTiles={this.state.chosenTiles}
              selectedMap={this.props.map}
              timestamp={
                this.props.map.timestamps.filter((t) => isValidTimestamp(t).available)[this.state.selectedTimestamp]
              }
            />
            <UserInfoBox maxZoom={this.state.maxZoom} currentZoom={this.state.currentZoom} />
          </div>

          <ButtonControlsWrapper
            setChosenTiles={() => this.setState({ chosenTiles: [], canSubmit: true })}
            chosenTiles={this.state.chosenTiles}
            mapType={this.props.map.type}
            setMaxZoom={() => {
              let currentZoom = Math.max(0, this.state.currentZoom);
              this.setState({ maxZoom: this.state.maxZoom ? null : currentZoom, canSubmit: true });
            }}
            maxZoom={this.state.maxZoom}
          />
        </DialogContent>
        <DialogActions>
          <Button className="dialog-actions-button" onClick={this.props.onCancel}>
            cancel
          </Button>
          <Button
            className="dialog-actions-button"
            disabled={!this.state.canSubmit}
            onClick={() => {
              if (!this.state.canSubmit) {
                return;
              }
              this.onSubmit(this.state.maxZoom, this.state.chosenTiles);
            }}
            variant="contained"
            color="primary"
          >
            submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMainContext(Geofence);

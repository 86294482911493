import React from 'react';

import {
  Button,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Cancel, Crop, KeyboardArrowDown } from '@mui/icons-material';

import { LinkStyleButton } from 'ReusableComponents';
import DateRangePicker from 'ReusableComponents/DateRangePicker/DateRangePicker';
import { ACTIVE_CONSTELLATIONS, CLOUD_MAX, CLOUD_MIN, CLOUD_OFF } from 'Constellation/Constellation';

const SearchPane = ({
  constellation,
  setConstellation,
  cloudCover,
  setCloudCover,
  dates,
  setDates,
  bounds,
  setBounds,
  drawBounds,
  handleBoundsClick,
  handleSearch,
  loading,
  featureLength,
  searchOpen,
  setSearchOpen,
  hasCloudCover,
}) => (
  <Paper sx={{ position: 'relative' }}>
    <Paper component={Stack} gap={1} p={1} flexDirection="row" justifyContent="flex-end" sx={{ zIndex: 2 }}>
      <Button
        variant="outlined"
        onClick={() => setSearchOpen((old) => !old)}
        disabled={loading || !(featureLength >= 0)}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: (theme) => theme.transitions.create('transform'),
              transform: `rotate(${searchOpen ? '180' : '0'}deg)`,
            }}
          />
        }
      >
        Search
      </Button>
    </Paper>

    <Collapse in={searchOpen} sx={{ position: 'absolute', zIndex: 1, left: 0, right: 0, mt: '1px' }}>
      <Paper component={Stack} sx={{ overflowX: 'hidden' }} p={1} py={2} gap={2} elevation={searchOpen ? 1 : 0}>
        <List disablePadding>
          <StyledListItem>Platform Select</StyledListItem>
          <ListItem>
            <Tooltip
              title={
                ACTIVE_CONSTELLATIONS?.length === 1
                  ? `Only ${ACTIVE_CONSTELLATIONS?.[0]?.value} is supported now`
                  : undefined
              }
            >
              <FormControl fullWidth size="small">
                <InputLabel id="constellations-select-label">Platform</InputLabel>
                <Select
                  labelId="constellations-select-label"
                  value={constellation}
                  label="Platform"
                  onChange={(e) => setConstellation(e.target.value)}
                  disabled={constellation === null || ACTIVE_CONSTELLATIONS?.length === 1}
                >
                  {ACTIVE_CONSTELLATIONS.map(({ key, value, disabled }) => (
                    <MenuItem key={key} value={key} disabled={disabled}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </ListItem>

          <StyledListItem>Cloud Coverage</StyledListItem>
          <ListItem sx={{ pr: 0.5, gap: 2 }}>
            <Slider
              disabled={!hasCloudCover && constellation?.length > 0}
              size="small"
              valueLabelDisplay="auto"
              valueLabelFormat={(x) => `${x}%`}
              min={CLOUD_MIN}
              max={CLOUD_MAX}
              step={5}
              marks={[
                { value: CLOUD_MIN, label: `${CLOUD_MIN}%` },
                { value: CLOUD_MAX, label: `${CLOUD_MAX}%` },
              ]}
              value={cloudCover}
              onChange={(e, nv) => setCloudCover(nv)}
              sx={{ mx: 0.5 }}
            />
            <IconButton
              edge="end"
              size="small"
              disabled={
                (cloudCover[0] === CLOUD_OFF[0] && cloudCover[1] === CLOUD_OFF[1]) ||
                (!hasCloudCover && constellation?.length > 0)
              }
              onClick={() => setCloudCover(CLOUD_OFF)}
            >
              <Cancel fontSize="inherit" />
            </IconButton>
          </ListItem>

          <StyledListItem>Dates</StyledListItem>
          <ListItem sx={{ pr: 0.5, gap: 0.5 }}>
            <DateRangePicker value={dates} setValue={setDates} />
            <IconButton edge="end" size="small" disabled={!dates} onClick={() => setDates()}>
              <Cancel fontSize="inherit" />
            </IconButton>
          </ListItem>

          <StyledListItem
            secondary={
              <LinkStyleButton onClick={() => setBounds(null)} disabled={!bounds} sx={{ my: -1, mr: -0.75 }}>
                Clear
              </LinkStyleButton>
            }
          >
            Bounds
          </StyledListItem>
          <ListItem sx={{ gap: 1 }}>
            <ListItemText primary="Draw a rectangle on the map." primaryTypographyProps={{ variant: 'body2' }} />
            <IconButton color={drawBounds ? 'secondary' : 'primary'} edge="end" onClick={handleBoundsClick}>
              <Crop />
            </IconButton>
          </ListItem>
        </List>
        <Divider flexItem />
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => {
            setSearchOpen(false);
            handleSearch();
          }}
          disabled={loading}
          sx={{ alignSelf: 'flex-end' }}
        >
          Search
        </LoadingButton>
      </Paper>
    </Collapse>
  </Paper>
);

const StyledListItem = ({ children, secondary }) => (
  <ListItem sx={{ py: 0, 'li + &': { mt: 2 } }}>
    <ListItemText primary={children} primaryTypographyProps={{ variant: 'overline' }} />
    {secondary}
  </ListItem>
);

export default SearchPane;

import React from 'react';

import { Typography, TextField, MenuItem, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

import useStyles from './useFormSideStyles';

import { jobRoles, industries, registeringReasons } from './constants';

const SecondFormTab = ({
  companyName,
  handleCompanyNameChange,
  job,
  handleJobChange,
  industry,
  handleIndustryChange,
  reason,
  handleReasonChange,
  termsChecked,
  handleTermsChange,
  errors,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.textFieldContainer}>
        <TextField
          label="Company Name*"
          InputLabelProps={{ shrink: true }}
          placeholder="e.g. Company Name"
          value={companyName}
          onChange={handleCompanyNameChange}
          variant="outlined"
          type="text"
          autoComplete="organization"
          className={classes.textField}
          helperText={errors.companyName}
          error={errors.companyName !== '' && errors.companyName}
        />
      </div>
      <div className={classes.textFieldContainer}>
        <TextField
          label="Job Role*"
          InputLabelProps={{ shrink: true }}
          select
          value={job}
          onChange={handleJobChange}
          className={classes.textField}
          helperText={errors.job}
          variant="outlined"
          autoComplete="organization-title"
          error={errors.job !== '' && errors.job}
          SelectProps={{
            renderValue: (selected) => {
              if (selected === '') {
                return <em>Select</em>;
              }
              return selected;
            },
            displayEmpty: true,
            MenuProps: { PopoverClasses: { root: classes.popoverMenu } },
          }}
        >
          {jobRoles.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.textFieldContainer}>
        <TextField
          label="Industry*"
          InputLabelProps={{ shrink: true }}
          select
          value={industry}
          onChange={handleIndustryChange}
          className={classes.textField}
          helperText={errors.industry}
          variant="outlined"
          error={errors.industry !== '' && errors.industry}
          SelectProps={{
            renderValue: (selected) => {
              if (selected === '') {
                return <em>Select</em>;
              }
              return selected;
            },
            displayEmpty: true,
            MenuProps: { PopoverClasses: { root: classes.popoverMenu } },
          }}
        >
          {industries.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.textFieldContainer}>
        <TextField
          label="Reason for registering*"
          InputLabelProps={{ shrink: true }}
          select
          value={reason}
          onChange={handleReasonChange}
          className={classes.textField}
          helperText={errors.reason}
          variant="outlined"
          error={errors.reason && errors.reason !== ''}
          SelectProps={{
            renderValue: (selected) => {
              if (selected === '') {
                return <em>Select</em>;
              }
              return selected;
            },
            displayEmpty: true,
            MenuProps: { PopoverClasses: { root: classes.popoverMenu } },
          }}
        >
          {registeringReasons.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <FormControlLabel
        control={<Checkbox color="primary" checked={termsChecked} onChange={handleTermsChange} />}
        label={
          <Typography className={classes.checkboxLabel}>
            Agree to{' '}
            <Typography
              component="a"
              className={classes.checkboxLink}
              color="primary"
              target="_blank"
              rel="noreferrer"
              href="https://ellipsis-drive.com/terms"
            >
              Terms of Service
            </Typography>{' '}
            and{' '}
            <Typography
              component="a"
              className={classes.checkboxLink}
              color="primary"
              target="_blank"
              rel="noreferrer"
              href="https://ellipsis-drive.com/privacy"
            >
              Privacy Policy
            </Typography>
            .
          </Typography>
        }
      />
      {errors.terms && (
        <FormHelperText className={classes.termsErrorText}>Agreement is required for sign up.</FormHelperText>
      )}
    </>
  );
};

export default SecondFormTab;

import React, { useState, useEffect, useRef } from 'react';

import { Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useQueryParams, useAuthFuncs, useInput, useDebounce } from 'hooks';
import { checkUsername, checkEmail, checkPassword, checkNotEmpty } from '../../utils';

import ApiManager from 'ApiManager';
import { LoadingButton, useMainContext } from 'ReusableComponents';
import useStyles from './useFormSideStyles';
import Container from './Container';
import HiddenHubspotFields from './HiddenHubspotFields';
import FirstFormTab from './FirstFormTab';
import SecondFormTab from './SecondFormTab';
import Tab from './Tab';
import { COOKIE_TYPES, useConsentValue, usePushToDataLayer } from 'ConsentContext';
import { CLOUDS } from 'ApiUrls';

const checkTerms = (terms, setErrors) => {
  if (!terms) {
    setErrors((prev) => ({ ...prev, terms: true }));
    return false;
  }
  setErrors((prev) => ({ ...prev, terms: false }));
  return true;
};

const FormSide = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { signin } = useAuthFuncs();

  const { validationToken, userId, invitedByUserId } = useQueryParams();
  const { hubspotUrlInfo } = useMainContext();
  const [username, handleUsernameChange] = useInput();
  const debouncedUsername = useDebounce(username, 500);
  const [email, handleEmailChange] = useInput();
  const debouncedEmail = useDebounce(email, 500);
  const [phone, handlePhoneChange] = useInput();

  const [password, handlePasswordChange] = useInput();
  const debouncedPassword = useDebounce(password, 500);
  const [termsChecked, setTermsChecked] = useState(false);

  const [companyName, handleCompanyNameChange] = useInput();
  const [job, handleJobChange] = useInput();
  const [industry, handleIndustryChange] = useInput();
  const [reason, handleReasonChange] = useInput();

  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const consent = useConsentValue();
  const pushToDataLayer = usePushToDataLayer();

  const [errors, setErrors] = useState({
    username: '',
    email: '',
    phone: '',
    password: '',
    terms: false,
    submit: '',
    companyName: '',
    job: '',
    industry: '',
    reason: '',
  });

  const itemsRef = useRef([]);

  const handleTermsChange = (event) => {
    if (event.target.checked) setErrors((prev) => ({ ...prev, terms: false }));

    setTermsChecked(event.target.checked);
  };

  useEffect(() => {
    if (debouncedUsername !== '') checkUsername(debouncedUsername.trim(), setErrors);
  }, [debouncedUsername]);

  useEffect(() => {
    if (debouncedEmail !== '') checkEmail(debouncedEmail.trim(), setErrors);
  }, [debouncedEmail]);

  useEffect(() => {
    if (debouncedPassword !== '') checkPassword(debouncedPassword.trim(), setErrors);
  }, [debouncedPassword]);

  useEffect(() => {
    if (companyName !== '') {
      checkNotEmpty(companyName.trim(), setErrors, 'companyName', 'Company Name');
    }
    if (job !== '') {
      checkNotEmpty(job.trim(), setErrors, 'job', 'Job');
    }
    if (industry !== '') {
      checkNotEmpty(industry.trim(), setErrors, 'industry', 'Industry');
    }
    if (reason !== '') {
      checkNotEmpty(reason.trim(), setErrors, 'reason', 'Reason for registering');
    }
  }, [companyName, job, industry, reason]);

  Object.fromEntries(
    itemsRef?.current?.map((input) => {
      return [input.name, input.value];
    })
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    if (tabValue === 0 && ApiManager.cloud === 'ellipsis') {
      const validUsername = await checkUsername(username.trim(), setErrors);
      const validEmail = validationToken || checkEmail(email.trim(), setErrors);
      const validPassword = checkPassword(password.trim(), setErrors);
      console.log(validUsername);
      if (!validUsername || !validEmail || !validPassword) {
        return;
      }

      setTabValue(1);
      return;
    }

    const validCompanyName = checkNotEmpty(companyName.trim(), setErrors, 'companyName', 'Company Name');
    const validJob = checkNotEmpty(job.trim(), setErrors, 'job', 'Job');
    const validIndustry = checkNotEmpty(industry.trim(), setErrors, 'industry', 'Industry');
    const validReason = checkNotEmpty(reason.trim(), setErrors, 'reason', 'Reason for registering');
    const validTerms = checkTerms(termsChecked, setErrors);

    if (
      ApiManager.cloud === 'ellipsis' &&
      (!validCompanyName || !validJob || !validIndustry || !validReason || !validTerms)
    ) {
      return;
    }

    const hubspotInfo = Object.fromEntries(itemsRef?.current?.map((input) => [input.name, input.value]));

    let body = {
      username: username.trim(),
      password: password.trim(),
      hubspotInfo: {
        cookie_consent_on_registration: consent,
        ...(consent?.[COOKIE_TYPES?.ANALYTICAL] ? { ...hubspotInfo } : {}),
        submission_url: hubspotUrlInfo,
        phone: phone.trim(),
        company: companyName.trim(),
        jobtitle: job.trim(),
        industry: industry.trim(),
        reason_for_registering: reason.trim(),
      },
    };

    if (validationToken) {
      body.validationToken = validationToken;
      body.userId = userId;
    } else {
      body.email = email.trim();
    }

    if (invitedByUserId) {
      body.invitedByUserId = invitedByUserId;
    }

    setLoading(true);
    try {
      await ApiManager.post('/v3/account', body);
      pushToDataLayer({
        dataLayer: {
          event: 'register-form-send',
        },
      });

      setErrors({
        username: '',
        password: '',
        submit: '',
      });
      if (validationToken) {
        try {
          let token = await ApiManager.post(
            '/v3/account/login',
            { username: body.username, password: body.password },
            null
          );

          signin(token, () => {
            if (invitedByUserId) {
              history.push('/drive/me');
            } else {
              history.push('/drive/me?notification=true');
            }
          });
        } catch {
          history.push('/drive/me');
        }
      } else {
        history.push('/verify');
      }
    } catch (error) {
      setErrors((prev) => ({ ...prev, submit: error.message }));
    }
    setLoading(false);
  };

  return (
    <Container>
      <Typography className={classes.title} variant="h3">
        {`Let's get you set up`}
      </Typography>

      {errors.submit && (
        <div className={classes.errorContainer}>
          <Typography className={classes.errorText}>{errors.submit}</Typography>
        </div>
      )}

      <form onSubmit={onSubmit} className={classes.form}>
        {ApiManager.cloud === CLOUDS.ellipsis && <HiddenHubspotFields itemsRef={itemsRef} />}
        <Tab value={tabValue} index={0}>
          <FirstFormTab
            username={username}
            handleUsernameChange={handleUsernameChange}
            email={email}
            validationToken={validationToken}
            handleEmailChange={handleEmailChange}
            phone={phone}
            handlePhoneChange={handlePhoneChange}
            password={password}
            handlePasswordChange={handlePasswordChange}
            errors={errors}
          />
        </Tab>
        <Tab value={tabValue} index={1}>
          <SecondFormTab
            companyName={companyName}
            handleCompanyNameChange={handleCompanyNameChange}
            job={job}
            handleJobChange={handleJobChange}
            industry={industry}
            handleIndustryChange={handleIndustryChange}
            reason={reason}
            handleReasonChange={handleReasonChange}
            termsChecked={termsChecked}
            handleTermsChange={handleTermsChange}
            errors={errors}
          />
        </Tab>

        <LoadingButton
          loading={loading}
          disabled={
            (tabValue === 0 &&
              (!username ||
                (!email && !validationToken) ||
                !password ||
                errors.username ||
                errors.password ||
                errors.email)) ||
            (tabValue === 1 && (!termsChecked || !companyName || !job || !industry || !reason))
          }
          variant="contained"
          color="primary"
          className={classes.submitButton}
          type="submit"
        >
          {tabValue === 0 && ApiManager.cloud === 'ellipsis' ? 'Next' : 'Register'}
        </LoadingButton>
        {tabValue === 1 ? (
          <Button variant="text" onClick={() => setTabValue(0)} className={classes.backButton}>
            Back
          </Button>
        ) : null}
      </form>
    </Container>
  );
};

export default FormSide;
